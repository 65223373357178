import React, { FunctionComponent, PropsWithChildren, Suspense } from 'react';
import { IntlWrapper } from 'i18n';
import { StateInspector as _StateInspector } from 'reinspect';
import { persistor, StoreType } from 'store/store';
import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import DomainWrapper from 'context/domain/domain-wrapper';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ModalProvider } from 'app-common/components/ModalProvider/ModalProvider';
import { NotificationProvider } from 'context/notification/notification-provider';

const StateInspector = _StateInspector as FunctionComponent<
  PropsWithChildren<{ name: string }>
>;

const AppShell = ({
  store,
  domain,
  children,
  queryClient,
  includeDevTools = false,
  AuthTokenProvider,
  Router,
}: React.PropsWithChildren<{
  store: StoreType;
  domain?: string;
  queryClient: QueryClient;
  includeDevTools?: boolean;
  AuthTokenProvider: React.ComponentType<React.PropsWithChildren>;
  Router: React.ComponentType<React.PropsWithChildren>;
}>) => {
  let commonShell = (
    <DomainWrapper domain={domain}>
      <Suspense fallback={<></>}>
        <QueryClientProvider client={queryClient}>
          {includeDevTools && <ReactQueryDevtools initialIsOpen={false} />}
          <Router>
            <StoreProvider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <AuthTokenProvider>
                  <IntlWrapper>
                    <NotificationProvider>
                      <ModalProvider>{children}</ModalProvider>
                    </NotificationProvider>
                  </IntlWrapper>
                </AuthTokenProvider>
              </PersistGate>
            </StoreProvider>
          </Router>
        </QueryClientProvider>
      </Suspense>
    </DomainWrapper>
  );

  if (includeDevTools) {
    commonShell = <StateInspector name='Epassi'>{commonShell}</StateInspector>;
  }

  return commonShell;
};

export default AppShell;
