import { ReactNode } from 'react';
import cn from 'classnames';
import styles from './IconInner.module.scss';

export type IconSize = 'AUTO' | 'SMALL' | 'MEDIUM' | 'LARGE';

const MapSizeClass = new Map<IconSize, string>([
  ['AUTO', styles.auto],
  ['SMALL', styles.small],
  ['MEDIUM', styles.medium],
  ['LARGE', styles.large],
]);

interface Props {
  children: ReactNode;
  name: string;
  size?: IconSize;
  className?: string;
  onClick?: () => void;
  light?: boolean;
}

const IconInner = ({
  children,
  name,
  className,
  size = 'LARGE',
  onClick,
  light = false,
}: Props) => {
  const sizeClass = MapSizeClass.get(size);

  return (
    <div
      className={cn(styles.icon, sizeClass, className, {
        [styles.light]: light,
      })}
      onClick={onClick}
      role={!!onClick ? 'button' : 'img'}
      data-test={`icon-${name}`}
      aria-label={`icon-${name}`}
    >
      {children}
    </div>
  );
};

export default IconInner;
