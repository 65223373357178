import { defineMessages } from 'react-intl';

type MessageKeys =
  | 'email'
  | 'max'
  | 'maxString'
  | 'min'
  | 'minString'
  | 'required'
  | 'mixedNumberTypeError'
  | 'mixedStringTypeError'
  | 'mixedDateTypeError'
  | 'mixedGeneralTypeError'
  | 'positive';

const messages = defineMessages<MessageKeys>({
  email: {
    id: 'yup.emailInvalidFormat',
    defaultMessage: 'Invalid email address',
  },
  max: {
    id: 'yup.max',
    defaultMessage: 'Must be lower than or equal to {value}',
  },
  maxString: {
    id: 'yup.max_string',
    defaultMessage: 'Must be lower than or equal to {value} characters',
  },
  min: {
    id: 'yup.min',
    defaultMessage: 'Must be greater than or equal to {value}',
  },
  minString: {
    id: 'yup.min_string',
    defaultMessage: 'Must be greater than or equal to {value} characters',
  },
  required: {
    id: 'yup.required',
    defaultMessage: 'This field is required',
  },
  mixedNumberTypeError: {
    id: 'yup.mixed.number_type_error',
    defaultMessage: 'This field must contain number',
  },
  mixedStringTypeError: {
    id: 'yup.mixed.string_type_error',
    defaultMessage: 'This field must contain string',
  },
  mixedDateTypeError: {
    id: 'yup.mixed.date_type_error',
    defaultMessage: 'This field must contain valid date',
  },
  mixedGeneralTypeError: {
    id: 'yup.mixed.general_type_error',
    defaultMessage: 'This field contains wrong type',
  },
  positive: {
    id: 'yup.number.positive',
    defaultMessage: 'This field must contain a positive number',
  },
});

export default messages;
