import React from 'react';
import useSynthesizedLocale from 'i18n/locales/useSynthesizedLocale';
import { RawIntlProvider } from 'react-intl';
import { LocaleContextProvider } from 'i18n/contexts/useLocale';
import { useYupLocale } from 'app-common/utils/intl-wrapper/yup-locale';

const ReactIntl = ({ children }: React.PropsWithChildren<{}>) => {
  const synthesizedLocale = useSynthesizedLocale();
  useYupLocale();
  return (
    <RawIntlProvider value={synthesizedLocale}>{children}</RawIntlProvider>
  );
};

const IntlWrapper = ({ children }: React.PropsWithChildren<{}>) => (
  <LocaleContextProvider>
    <ReactIntl>{children}</ReactIntl>
  </LocaleContextProvider>
);

export default IntlWrapper;
