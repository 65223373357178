import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BenefitsState = {
  benefitsRules?: BenefitsRules;
};

export const initialState: BenefitsState = {
  benefitsRules: void 0,
};

export const STATE_NAMESPACE = 'benefits';

export const benefitsSlice = createSlice({
  name: STATE_NAMESPACE,
  initialState,
  reducers: {
    setBenefitsRules: (
      state,
      action: PayloadAction<BenefitsState['benefitsRules']>,
    ) => {
      state.benefitsRules = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBenefitsRules } = benefitsSlice.actions;

export default benefitsSlice.reducer;
