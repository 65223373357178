import { getUserFeatures } from 'api/user';
import { createContextProp } from 'context/context-tools';
import { RoutePaths } from 'globals/routes';
import localStorageService, {
  AUTH_TOKEN_KEY,
} from 'local-storage/local-storage';
import { removeUnusedDataFromLocalStorage } from 'utils/local-storage';
import {
  createContext,
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { ContextProp } from './context';

export enum LoginRedirect {
  MERCHANT_SEARCH,
}

export interface AuthContextProps {
  authToken: string;
  setAuthToken: (token: string | undefined, withoutRedirect?: boolean) => void;
  loginRedirect: ContextProp<LoginRedirect | undefined>;
  enabledFeatures: ContextProp<string[]>;
}

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps,
);

interface Props {
  children?: ReactNode;
}

export const useAuthToken = () => {
  const value = useContext(AuthContext);

  if (!value) {
    throw new Error(
      'useAuthToken can only be used inside the AuthContext.Provider',
    );
  }

  return value;
};

export const AuthTokenProvider: FunctionComponent<Props> = ({ children }) => {
  const [authToken, SetAuthToken] = useState(
    localStorageService.get(AUTH_TOKEN_KEY) as string,
  );
  const [loginRedirect, setLoginRedirect] = useState<LoginRedirect>();
  const [enabledFeatures, setEnabledFeatures] = useState<string[]>([]);

  const navigate = useNavigate();

  const clearAndNavigate = useCallback(
    (withoutRedirect: boolean) => {
      removeUnusedDataFromLocalStorage();
      if (!withoutRedirect) {
        navigate(RoutePaths.LOGIN);
      }
    },
    [navigate],
  );

  const setAuthToken = useCallback(
    (token = '', withoutRedirect = false) => {
      SetAuthToken(token);
      if (token) {
        localStorageService.set(AUTH_TOKEN_KEY, token);
      } else {
        clearAndNavigate(withoutRedirect);
      }
    },
    [clearAndNavigate],
  );

  useEffect(() => {
    getUserFeatures(
      (response) => {
        setEnabledFeatures(response.data.response);
      },
      (error) => console.error(error),
    );
  }, []);

  const value = useMemo(
    () => ({
      authToken,
      setAuthToken,
      loginRedirect: createContextProp<LoginRedirect | undefined>(
        loginRedirect,
        setLoginRedirect,
      ),
      enabledFeatures: createContextProp<string[]>(
        enabledFeatures,
        setEnabledFeatures,
      ),
    }),
    [authToken, enabledFeatures, loginRedirect, setAuthToken],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
