import { QueryClient } from '@tanstack/react-query';
import React, { lazy, StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppShell from 'AppShell';
import { AuthTokenProvider } from 'context/auth-context';
import { store } from 'store/store';
import { isDevEnv } from 'utils/environment';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = lazy(() => import('App'));
const Minimal = lazy(() => import('Minimal'));

const AppByClient = () => {
  return window.location.pathname.startsWith('/ui/minimal') ? (
    <Minimal />
  ) : (
    <App />
  );
};

const Router = (props: React.PropsWithChildren) => (
  <BrowserRouter basename='/ui' {...props} />
);

const RootComponent = () => (
  <StrictMode>
    <AppShell
      includeDevTools={isDevEnv}
      store={store}
      queryClient={queryClient}
      AuthTokenProvider={AuthTokenProvider}
      Router={Router}
    >
      <AppByClient />
    </AppShell>
  </StrictMode>
);

export default RootComponent;
