import { createSlice } from '@reduxjs/toolkit';
import { CapabilityParams } from 'api/user';

export const STATE_NAMESPACE = 'authorization';

export type AuthorizationState = {
  error: string | undefined;
  loading: boolean;
  authorized: boolean;
  data: CapabilityParams;
};

export const initialState: AuthorizationState = {
  error: '',
  loading: false,
  authorized: false,
  data: {
    subject_id: undefined,
    subject_type: '',
    country: '',
  },
};

export const authorizationSlice = createSlice({
  name: STATE_NAMESPACE,
  initialState,
  reducers: {
    resetCapabilityParams: () => initialState,
  },
});

export const { resetCapabilityParams } = authorizationSlice.actions;

export default authorizationSlice.reducer;
