import { AccountType, Domain } from 'namespace/common';
import localStorageService, {
  CURRENT_USER_KEY,
  DOMAIN_KEY,
  LANGUAGE_KEY,
} from 'local-storage/local-storage';

export const mapUrl = (address: string) =>
  `https://maps.google.com/?q=${address}`;

export const phoneUrl = (phone: string) => `tel:${phone}`;

export const saveQuery = (name: string, value: string) => {
  const query = new URLSearchParams(window.location.search);
  query.set(name, value);
  window.history.pushState(null, '', `?${query.toString()}`);
};

export const getUrlParameter = (paramName: string) => {
  const query = new URLSearchParams(window.location.search);
  return query.get(paramName);
};

export const removeQuery = (name: string) => {
  const query = new URLSearchParams(window.location.search);
  if (query.get(name)) {
    query.delete(name);
    window.history.pushState(null, '', `?${query.toString()}`);
  }
};

export const getBackendUrl = () => {
  const domain = localStorageService.get(DOMAIN_KEY);
  if (domain === Domain.FI && !!window.ENV.REACT_APP_BACKEND_HOST_FI) {
    return window.ENV.REACT_APP_BACKEND_HOST_FI;
  }
  if (domain === Domain.SE && !!window.ENV.REACT_APP_BACKEND_HOST_SE) {
    return window.ENV.REACT_APP_BACKEND_HOST_SE;
  }
  if (domain === Domain.COM && !!window.ENV.REACT_APP_BACKEND_HOST_COM) {
    return window.ENV.REACT_APP_BACKEND_HOST_COM;
  }
  return window.ENV.REACT_APP_BACKEND_HOST;
};

export const getRedirectUrl = () => {
  const currentUser = localStorageService.get(CURRENT_USER_KEY);
  if (currentUser === AccountType.ADMIN && !!window.ENV.REACT_APP_ADMIN_HOST) {
    return window.ENV.REACT_APP_ADMIN_HOST;
  } else {
    return getBackendUrl();
  }
};

export const makeSporttiPassiURL = (
  path?: string,
  params?: [{ key: string; value: string }],
) => {
  const language = localStorageService.get(LANGUAGE_KEY);

  const url = new URL(`${getBackendUrl()}${path || ''}`);
  if (!!language) {
    url.searchParams.append('l', language);
  }
  if (params) {
    params.forEach(({ key, value }) => {
      url.searchParams.append(key, value);
    });
  }
  return url.toString();
};

export function getBase64Param<T>(paramName: string): T | undefined {
  const paramValue = getUrlParameter(paramName);
  return paramValue ? (JSON.parse(atob(paramValue)) as T) : undefined;
}

export const isValidUrl = (url: string) => {
  return /^(https?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w.~:/\-?#[\]@%!$&'()*+,;=]+$/.test(
    url,
  );
};
