import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AccountType, Country } from 'namespace/common';

export type ProfileState = {
  accountType?: AccountType;
  entityType?: AccountType;
  loggedIn?: boolean;
  name?: string;
  id?: number;
  entityId?: number;
  adminId?: string;
  adminName?: string;
  country?: Country;
};

export const initialState: ProfileState = {
  accountType: void 0,
  loggedIn: void 0,
  entityId: void 0,
  country: void 0,
};

export const STATE_NAMESPACE = 'profile';

export const profileSlice = createSlice({
  name: STATE_NAMESPACE,
  initialState,
  reducers: {
    resetProfile: () => initialState,
    setProfile: (state, action: PayloadAction<ProfileState>) => ({
      ...state,
      ...action.payload,
    }),
    setLoggedIn: (state, action: PayloadAction<ProfileState['loggedIn']>) => {
      state.loggedIn = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { resetProfile, setProfile, setLoggedIn } = profileSlice.actions;

export default profileSlice.reducer;
