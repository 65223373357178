import { defineMessages } from 'react-intl';

type MessageKeys =
  | 'culture'
  | 'ergosafe'
  | 'flex'
  | 'health2'
  | 'lunch'
  | 'sport'
  | 'travel'
  | 'personal'
  | 'friskvard'
  | 'bicycle'
  | 'privateHealthcare';

const messages = defineMessages<MessageKeys>({
  bicycle: {
    id: 'benefit_type.bicycle',
    defaultMessage: 'Bicycle',
  },
  culture: {
    id: 'benefit_type.culture',
    defaultMessage: 'Culture',
  },
  ergosafe: {
    id: 'benefit_type.ergosafe',
    defaultMessage: 'Ergosafe',
  },
  flex: {
    id: 'benefit_type.flex',
    defaultMessage: 'Flex',
  },
  friskvard: {
    id: 'benefit_type.friskvard',
    defaultMessage: 'Wellness',
  },
  health2: {
    id: 'benefit_type.health_2',
    defaultMessage: 'Wellbeing',
  },
  lunch: {
    id: 'benefit_type.lunch',
    defaultMessage: 'Lunch',
  },
  personal: {
    id: 'benefit_type.personal',
    defaultMessage: 'MyMoney',
  },
  privateHealthcare: {
    id: 'benefit_type.private_healthcare',
    defaultMessage: 'Private Healthcare',
  },
  sport: {
    id: 'benefit_type.sport',
    defaultMessage: 'Sport',
  },
  travel: {
    id: 'benefit_type.travel',
    defaultMessage: 'Commuting',
  },
});

export default messages;
