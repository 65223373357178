import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTransition, animated } from '@react-spring/web';
import {
  ValidationMessage,
  ValidationMessageType,
} from 'components/validation-message/validation-message';
import { Icon } from 'app-common/components/Icon/Icon';
import styles from './notification.module.scss';

export type NotificationMessage = {
  title?: React.ReactNode;
  description?: React.ReactNode;
  preserve?: boolean;
  closeTime?: number;
};

export type NotificationMessageType = {
  type: ValidationMessageType;
} & NotificationMessage;

interface Props extends NotificationMessageType {
  closeNotification: () => void;
}

const TAG = 'NotificationContainer';

export const Notification = ({
  title,
  description,
  type,
  closeNotification,
  preserve = false,
  closeTime = 6000,
}: Props) => {
  const transitions = useTransition(true, {
    from: { transform: 'translateX(25rem)' },
    enter: { transform: 'translateX(0rem)' },
    leave: { transform: 'translateX(25rem)' },
  });

  useEffect(() => {
    const id =
      !preserve &&
      setTimeout(() => {
        closeNotification();
      }, closeTime);

    return () => {
      if (id) clearTimeout(id);
    };
  }, [closeNotification, closeTime, preserve]);

  return ReactDOM.createPortal(
    <div className={styles.container} data-test={TAG}>
      {transitions(
        (style, item, _, key) =>
          item && (
            <animated.div style={style} key={key}>
              <div className={styles.notificationContainer}>
                <ValidationMessage
                  title={title}
                  message={description}
                  type={type}
                  buttonText={
                    preserve ? <Icon type='CLOSE_WINDOW' size='SMALL' /> : null
                  }
                  onButtonClick={closeNotification}
                />
              </div>
            </animated.div>
          ),
      )}
    </div>,
    document.body,
  );
};
