import ApiProperties from 'api/ApiProperties';
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { IntlLanguage } from 'i18n';
import localStorageService, {
  AUTH_TOKEN_KEY,
  LANGUAGE_KEY,
} from 'local-storage/local-storage';
import { getBackendUrl } from 'utils/url.utils';
import { Duration } from 'iso8601-duration';

export interface BaseResponseData<T> {
  status_code: string;
  response: T;
}

export interface ErrorResponse {
  error: {
    code: string;
    message: string;
  };
}

export function isValidResponse<T>(
  response: BaseResponse<T | ErrorResponse> | ErrorResponse,
): response is BaseResponse<T> {
  return (
    (response as BaseResponse<T | ErrorResponse>).data.status_code === 'OK'
  );
}

export type BaseResponse<T> = AxiosResponse<BaseResponseData<T>>;

export const REFRESH_TOKEN_EVENT = 'onAuthToken';

const refreshToken = () => {
  /**
   * If status is 401 unauthorized, dispatch token refresh event (handler in App.tsx)
   */
  document.dispatchEvent(new CustomEvent(REFRESH_TOKEN_EVENT));
};

export const getAuthToken = (
  onetimetoken: string,
): Promise<{ token: string }> =>
  axios
    .post(`${getBackendUrl()}${ApiProperties.TOKEN_EXCHANGE}`, {
      token: onetimetoken,
    })
    .then((res) => {
      return res.data;
    });

export const useApi = () => {
  let request: AxiosInstance;

  const getRequest = (): Promise<AxiosInstance> => {
    if (request !== undefined) {
      return Promise.resolve(request);
    }

    const baseURL = getBackendUrl();

    const result = axios.create();
    result.defaults.baseURL = baseURL;
    result.defaults.headers.common['Content-Type'] = 'application/json';
    result.defaults.headers.common.Authorization =
      localStorageService.get(AUTH_TOKEN_KEY) || '';
    result.defaults.headers.common['Accept-Language'] = localStorageService.get(
      LANGUAGE_KEY,
    ) as IntlLanguage;

    result.interceptors.response.use(
      async (response: AxiosResponse) => {
        if (response.status >= 200 && response.status < 300) return response;
        if (response.status === 401) refreshToken();
        return Promise.reject(response);
      },
      (error: AxiosError) => {
        const ignoredPaths = [
          '/api/user/v1/features',
          '/api/authorize/action/',
          '/api/user/login/v1/email_verification',
          '/api/check_token_validity',
          '/api/employer/login/v1',
          '/api/merchant/login/v1',
          '/api/user/v1',
          '/api/legal_agreement/v1',
          '/api/user/login/v1/verification',
          '/api/user/login/v1/bank_id/check',
          '/api/user/login/v1/secret_proof',
          '/api/secrets/v1/challenge/verify',
        ];
        const notGetMethod =
          error.response &&
          error.response.config?.method?.toLowerCase() !== 'get';

        if (
          error.response?.status === 401 &&
          error.response?.config.url &&
          (!ignoredPaths.some((path) =>
            error.response?.config?.url?.startsWith(path),
          ) ||
            (error.response?.config.url === '/api/user/v1' && notGetMethod))
        ) {
          window.location.href = '/ui/login?reason=session_timeout';
        }
        return Promise.reject(error);
      },
    );

    request = result;
    return Promise.resolve(result);
  };

  const updateHeaders = (req: AxiosInstance) => {
    req.defaults.headers.common.Authorization =
      localStorageService.get(AUTH_TOKEN_KEY) || '';
    req.defaults.headers.common['Accept-Language'] = localStorageService.get(
      LANGUAGE_KEY,
    ) as IntlLanguage;
    return req;
  };

  const get = <T = any>(
    url: string,
    params?: any,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<T>> => {
    const config = { params, ...options };

    return getRequest().then((req) => {
      updateHeaders(req);
      return req.get<T>(url, config);
    });
  };

  const post = <T = any>(
    url: string,
    data?: T,
    options?: AxiosRequestConfig,
    skipAuthToken?: boolean,
  ): Promise<AxiosResponse> =>
    getRequest().then((req) => {
      updateHeaders(req);
      if (skipAuthToken || !req.defaults.headers.common.Authorization) {
        delete req.defaults.headers.common.Authorization;
      }
      return req.post(url, data, options);
    });

  const put = <T = any>(
    url: string,
    data?: T,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse> =>
    getRequest().then((req) => {
      updateHeaders(req);
      return req.put<T>(url, data, options);
    });

  const del = (
    url: string,
    data?: AxiosRequestConfig,
  ): Promise<AxiosResponse> =>
    getRequest().then((req) => {
      updateHeaders(req);
      return req.delete(url, data);
    });

  const escapedBtoa = (str: string) =>
    btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) =>
        String.fromCharCode(parseInt(p1, 16)),
      ),
    );

  return {
    get,
    post,
    del,
    put,
    escapedBtoa,
  };
};

export const toJsonDate = (date: Date | null | undefined) =>
  date?.toJSON()?.split('T')[0];

type JsonNative = null | undefined | string | number | bigint | boolean;

type JsonCompatible = JsonNative | Date | Duration;

export type JsonConvertible =
  | JsonCompatible
  | { [K: number]: JsonConvertible }
  | { [K: string]: JsonConvertible };

type SimpleJsonType<T extends JsonCompatible> = T extends Date
  ? string
  : T extends Duration
    ? string
    : T extends null
      ? undefined
      : T extends JsonNative
        ? T
        : never;

export type JsonType<T extends JsonConvertible> = T extends JsonCompatible
  ? SimpleJsonType<T>
  : T extends JsonConvertible[]
    ? JsonType<T[number]>[]
    : {
        [P in keyof T]: T[P] extends JsonConvertible ? JsonType<T[P]> : never;
      };
