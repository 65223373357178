import { useEffect, useMemo, useState } from 'react';
import useLocale from 'i18n/contexts/useLocale';
import { createIntl, createIntlCache, IntlShape } from 'react-intl';
import { DefaultLocale, IntlLanguage } from './intl-locale';
import localStorageService, { LANGUAGE_KEY } from 'local-storage/local-storage';

const getMessages = async (
  lang: string,
  localeDefault: string,
): Promise<Record<string, string>> => {
  try {
    return {
      ...(await import(
        /* @vite-ignore */ `app-common/translations/${lang}.json`
      )),
      ...(await import(/* @vite-ignore */ `translations/${lang}.json`)),
    };
  } catch (e) {
    return import(/* @vite-ignore */ `translations/${localeDefault}.json`);
  }
};

const rebindFormattersToDefaultLanguage = (
  intl: IntlShape,
  defaultLanguageIntl?: IntlShape,
) => {
  intl.formatDate = defaultLanguageIntl?.formatDate ?? intl.formatDate;
  intl.formatDateTimeRange =
    defaultLanguageIntl?.formatDateTimeRange ?? intl.formatDateTimeRange;
  intl.formatDateToParts =
    defaultLanguageIntl?.formatDateToParts ?? intl.formatDateToParts;

  intl.formatNumber = defaultLanguageIntl?.formatNumber ?? intl.formatNumber;
  intl.formatNumberToParts =
    defaultLanguageIntl?.formatNumberToParts ?? intl.formatNumberToParts;
};

const useSynthesizedLocale = () => {
  const { locale, defaultLanguage } = useLocale();
  const intlCache = createIntlCache();
  const [messages, setMessages] = useState<Record<string, string>>({});

  useEffect(() => {
    const localStorageLanguage = localStorageService.get(LANGUAGE_KEY);
    if (localStorageLanguage) {
      const existingLocalStorageLanguage = Object.values(IntlLanguage).find(
        (language) => language === localStorageLanguage,
      );
      if (!existingLocalStorageLanguage) {
        localStorageService.remove(LANGUAGE_KEY);
      }
    }
  }, []);

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      try {
        const messagesByLocale = await getMessages(
          locale.language,
          DefaultLocale.language,
        );

        if (!isCancelled) {
          setMessages(messagesByLocale);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
    };
  }, [locale.language]);

  const defaultLanguageIntl = useMemo(
    () =>
      defaultLanguage
        ? createIntl({ locale: defaultLanguage }, intlCache)
        : undefined,
    [defaultLanguage, intlCache],
  );

  return useMemo(() => {
    const intl = createIntl(
      {
        locale: locale.icu,
        defaultLocale: DefaultLocale.icu,
        messages,
        fallbackOnEmptyString: true,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        onError: console.warn,
      },
      intlCache,
    );
    if (defaultLanguage !== locale.language) {
      rebindFormattersToDefaultLanguage(intl, defaultLanguageIntl);
    }
    return intl;
  }, [
    locale.icu,
    locale.language,
    messages,
    intlCache,
    defaultLanguage,
    defaultLanguageIntl,
  ]);
};

export default useSynthesizedLocale;
