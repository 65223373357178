import { CSSProperties, ReactNode, Ref, UIEvent } from 'react';
import { animated as a } from '@react-spring/web';
import { Col as C, Row as R } from 'antd';

export type ContainerType = 'div' | 'column' | 'row' | 'span';

export interface ContainerSizingProps {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
}

export interface ContainerProps {
  type?: ContainerType;
  className?: string;
  style?: CSSProperties | undefined;
  children?: ReactNode;
  colspan?: number;
  ref?: Ref<any>;
  testId?: string;
  responsiveSizing?: ContainerSizingProps;
  animated?: boolean;
  onClick?: () => void;
  handleScroll?: (event: UIEvent) => void;
}

export const Container = ({
  type = 'div',
  colspan = 24,
  children,
  className,
  responsiveSizing = {},
  onClick,
  ref,
  animated = false,
  handleScroll,
  testId,
  ...props
}: ContainerProps) => {
  const Div = animated ? a.div : 'div';
  const Span = animated ? a.span : 'span';
  const Col = animated ? a(C) : C;
  const Row = animated ? a(R) : R;

  switch (type) {
    case 'div':
    default:
      return (
        <Div
          onScroll={handleScroll}
          ref={ref}
          onClick={onClick}
          className={className}
          data-test={testId}
          {...props}
        >
          {children}
        </Div>
      );
    case 'span':
      return (
        <Span
          onScroll={handleScroll}
          ref={ref}
          onClick={onClick}
          className={className}
          data-test={testId}
          {...props}
        >
          {children}
        </Span>
      );
    case 'column':
      return (
        <Col
          onScroll={handleScroll}
          ref={ref}
          onClick={onClick}
          span={colspan}
          {...responsiveSizing}
          className={className}
          data-test={testId}
          {...props}
        >
          {children}
        </Col>
      );
    case 'row':
      return (
        <Row
          onScroll={handleScroll}
          ref={ref}
          onClick={onClick}
          className={className}
          data-test={testId}
          {...props}
        >
          {children}
        </Row>
      );
  }
};
