import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserInfo } from 'api/user';

export type UserInfoState = {
  error: string | undefined;
  loading: boolean;
  data: UserDetails | null;
};

export const initialState: UserInfoState = {
  error: '',
  loading: false,
  data: null,
};

export const STATE_NAMESPACE = 'userInfo';

export const fetchUserInfo = createAsyncThunk(
  `${STATE_NAMESPACE}/fetchUserInfo`,
  () => getUserInfo(),
);

export const userInfoSlice = createSlice({
  name: STATE_NAMESPACE,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchUserInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = '';
    });
    builder.addCase(fetchUserInfo.rejected, (state, action) => {
      state.data = null;
      state.loading = false;
      state.error = action.error.message;
    });
  },
  reducers: {
    resetUserInfo: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { resetUserInfo } = userInfoSlice.actions;

export default userInfoSlice.reducer;
