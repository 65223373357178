import { ReactNode } from 'react';
import { Button as AntButton } from 'antd';
import { ButtonProps as AntButtonProps } from 'antd/lib/button';
import cn from 'classnames';
import styles from './button.module.scss';
import typography from 'app-common/styles/typography.module.scss';
import './button.scss';

export type ButtonSizeType = 'small' | 'middle' | 'large';
export type ButtonType = 'primary' | 'default' | 'dashed' | 'text' | 'link';
export type ButtonShape = 'round' | 'circle';
export interface ButtonProps extends AntButtonProps {
  uppercase?: boolean;
  children?: ReactNode;
}

export const Button = ({
  type = 'default',
  size = 'middle',
  block = false,
  danger = false,
  shape = 'round',
  ghost = false,
  disabled = false,
  loading = false,
  uppercase = true,
  icon,
  children,
  className,
  ...props
}: ButtonProps) => {
  const classes = cn(
    styles[type],
    className,
    {
      [styles.danger]: danger,
      [styles.uppercase]: uppercase,
    },
    styles.button,
  );

  return (
    <AntButton
      danger={danger}
      block={block}
      shape={shape}
      type={type}
      size={size}
      disabled={disabled}
      loading={loading}
      ghost={ghost}
      icon={icon}
      className={classes}
      {...props}
    >
      <span className={typography.interBold16}>{children}</span>
    </AntButton>
  );
};
