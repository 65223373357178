import { FunctionComponent } from 'react';
import IconInner, {
  IconSize,
} from 'app-common/components/Icon/IconInner/IconInner';

import { ReactComponent as Address } from 'app-common/assets/icons/address.svg';
import { ReactComponent as AlertTriangle } from 'app-common/assets/icons/alert-triangle.svg';
import { ReactComponent as AlertCircle } from 'app-common/assets/icons/alert-circle.svg';
import { ReactComponent as ArrowsRigthLeft } from 'app-common/assets/icons/arrows-right-left.svg';
import { ReactComponent as AppStore } from 'app-common/assets/icons/app-store.svg';
import { ReactComponent as Arrow } from 'app-common/assets/icons/arrow.svg';
import { ReactComponent as ArrowDown } from 'app-common/assets/icons/arrow-down.svg';
import { ReactComponent as ArrowOutline } from 'app-common/assets/icons/arrow-outline.svg';
import { ReactComponent as ArrowUp } from 'app-common/assets/icons/arrow-up.svg';
import { ReactComponent as ArrowLeft } from 'app-common/assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'app-common/assets/icons/arrow-right.svg';
import { ReactComponent as ArrowRotation } from 'app-common/assets/icons/arrow-rotation.svg';
import { ReactComponent as BankID } from 'app-common/assets/icons/bank-id.svg';
import { ReactComponent as Bicycle } from 'app-common/assets/icons/bicycle.svg';
import { ReactComponent as Buy } from 'app-common/assets/icons/buy.svg';
import { ReactComponent as BuyNow } from 'app-common/assets/icons/buy-now.svg';
import { ReactComponent as Calendar } from 'app-common/assets/icons/calendar.svg';
import { ReactComponent as Checkmark } from 'app-common/assets/icons/checkmark.svg';
import { ReactComponent as ChevronDoubleIcon } from 'app-common/assets/icons/chevron-double.svg';
import { ReactComponent as ChevronDown } from 'app-common/assets/icons/chevron-down.svg';
import { ReactComponent as ChevronRight } from 'app-common/assets/icons/chevron-right.svg';
import { ReactComponent as CloseOutline } from 'app-common/assets/icons/close-outline.svg';
import { ReactComponent as CloseWindow } from 'app-common/assets/icons/close-window.svg';
import { ReactComponent as Cutlery } from 'app-common/assets/icons/cutlery.svg';
import { ReactComponent as Clock } from 'app-common/assets/icons/clock.svg';
import { ReactComponent as Copy } from 'app-common/assets/icons/copy.svg';
import { ReactComponent as Department } from 'app-common/assets/icons/department.svg';
import { ReactComponent as Download } from 'app-common/assets/icons/download.svg';
import { ReactComponent as Ellipsis } from 'app-common/assets/icons/ellipsis.svg';
import { ReactComponent as EmptyFile } from 'app-common/assets/icons/empty-file.svg';
import { ReactComponent as EpassiLogo } from 'app-common/assets/icons/epassi-logo.svg';
import { ReactComponent as EpassiLogoV2 } from 'app-common/assets/icons/epassi-logo-v2.svg';
import { ReactComponent as Estonia } from 'app-common/assets/icons/estonia.svg';
import { ReactComponent as EvaRefresh } from 'app-common/assets/icons/eva-refresh.svg';
import { ReactComponent as Eye } from 'app-common/assets/icons/eye.svg';
import { ReactComponent as Face } from 'app-common/assets/icons/face.svg';
import { ReactComponent as FileSearch } from 'app-common/assets/icons/file-search.svg';
import { ReactComponent as Filter } from 'app-common/assets/icons/filter.svg';
import { ReactComponent as Finland } from 'app-common/assets/icons/finland.svg';
import { ReactComponent as Friskvard } from 'app-common/assets/icons/friskvard.svg';
import { ReactComponent as Globe } from 'app-common/assets/icons/globe.svg';
import { ReactComponent as GlobeHuman } from 'app-common/assets/icons/globe-human.svg';
import { ReactComponent as GooglePlay } from 'app-common/assets/icons/google-play.svg';
import { ReactComponent as HeartFilled } from 'app-common/assets/icons/heart-filled.svg';
import { ReactComponent as Home } from 'app-common/assets/icons/home.svg';
import { ReactComponent as House } from 'app-common/assets/icons/house.svg';
import { ReactComponent as Info } from 'app-common/assets/icons/info.svg';
import { ReactComponent as InfoOutline } from 'app-common/assets/icons/info-outline.svg';
import { ReactComponent as IntlEn } from 'app-common/assets/icons/intl_en.svg';
import { ReactComponent as IntlFi } from 'app-common/assets/icons/intl_fi.svg';
import { ReactComponent as IntlSv } from 'app-common/assets/icons/intl_sv.svg';
import { ReactComponent as List } from 'app-common/assets/icons/list.svg';
import { ReactComponent as Lotus } from 'app-common/assets/icons/lotus.svg';
import { ReactComponent as Mail } from 'app-common/assets/icons/mail.svg';
import { ReactComponent as ManWalking } from 'app-common/assets/icons/man_walking.svg';
import { ReactComponent as Menu } from 'app-common/assets/icons/menu.svg';
import { ReactComponent as None } from 'app-common/assets/icons/none.svg';
import { ReactComponent as Pencil } from 'app-common/assets/icons/pencil.svg';
import { ReactComponent as Personal } from 'app-common/assets/icons/personal.svg';
import { ReactComponent as PersonOutline } from 'app-common/assets/icons/person-outline.svg';
import { ReactComponent as People } from 'app-common/assets/icons/people.svg';
import { ReactComponent as Phone } from 'app-common/assets/icons/phone.svg';
import { ReactComponent as Pin } from 'app-common/assets/icons/pin.svg';
import { ReactComponent as PlaceholderBicycle } from 'app-common/assets/icons/placeholders/bicycle.svg';
import { ReactComponent as PlaceholderCommute } from 'app-common/assets/icons/placeholders/commute.svg';
import { ReactComponent as PlaceholderCulture } from 'app-common/assets/icons/placeholders/culture.svg';
import { ReactComponent as PlaceholderErgosafe } from 'app-common/assets/icons/placeholders/ergosafe.svg';
import { ReactComponent as PlaceholderFriskvard } from 'app-common/assets/icons/placeholders/friskvard.svg';
import { ReactComponent as PlaceholderLunch } from 'app-common/assets/icons/placeholders/lunch.svg';
import { ReactComponent as PlaceholderPrivateHealthcare } from 'app-common/assets/icons/placeholders/private-healthcare.svg';
import { ReactComponent as PlaceholderSport } from 'app-common/assets/icons/placeholders/sport.svg';
import { ReactComponent as PlaceholderUnknown } from 'app-common/assets/icons/placeholders/unknown.svg';
import { ReactComponent as Plus } from 'app-common/assets/icons/plus.svg';
import { ReactComponent as PngFile } from 'app-common/assets/icons/png-file.svg';
import { ReactComponent as PriceTag } from 'app-common/assets/icons/price-tag.svg';
import { ReactComponent as QuestionMark } from 'app-common/assets/icons/question-mark.svg';
import { ReactComponent as RunningShoe } from 'app-common/assets/icons/running-shoe.svg';
import { ReactComponent as Rocket } from 'app-common/assets/icons/rocket.svg';
import { ReactComponent as Scales } from 'app-common/assets/icons/scales.svg';
import { ReactComponent as Search } from 'app-common/assets/icons/search.svg';
import { ReactComponent as Share } from 'app-common/assets/icons/share.svg';
import { ReactComponent as Shield } from 'app-common/assets/icons/shield.svg';
import { ReactComponent as ShieldFilled } from 'app-common/assets/icons/shield-filled.svg';
import { ReactComponent as Smartphone } from 'app-common/assets/icons/smartphone.svg';
import { ReactComponent as Star } from 'app-common/assets/icons/star.svg';
import { ReactComponent as Statistics } from 'app-common/assets/icons/statistics.svg';
import { ReactComponent as Success } from 'app-common/assets/icons/success.svg';
import { ReactComponent as Sweden } from 'app-common/assets/icons/sweden.svg';
import { ReactComponent as Theater } from 'app-common/assets/icons/theater.svg';
import { ReactComponent as Train } from 'app-common/assets/icons/train.svg';
import { ReactComponent as Upload } from 'app-common/assets/icons/upload.svg';
import { ReactComponent as User } from 'app-common/assets/icons/user.svg';
import { ReactComponent as Users } from 'app-common/assets/icons/users.svg';
import { ReactComponent as Wallet } from 'app-common/assets/icons/wallet.svg';
import { ReactComponent as WavingHand } from 'app-common/assets/icons/waving-hand.svg';
import { ReactComponent as XlsFile } from 'app-common/assets/icons/xls-file.svg';

export const iconNames = [
  'ADDRESS',
  'ALERT_TRIANGLE',
  'ALERT_CIRCLE',
  'ARROWS_RIGHT_LEFT',
  'APP_STORE',
  'ARROW_DOWN',
  'ARROW_OUTLINE',
  'ARROW_UP',
  'ARROW_LEFT',
  'ARROW_RIGHT',
  'ARROW_ROTATION',
  'ARROW',
  'BANKID',
  'BICYCLE',
  'BUY',
  'BUY_NOW',
  'CALENDAR',
  'CHECKMARK',
  'CHEVRON_DOUBLE',
  'CHEVRON_DOWN',
  'CHEVRON_RIGHT',
  'CLOCK',
  'CLOSE_OUTLINE',
  'CLOSE_WINDOW',
  'COPY',
  'CUTLERY',
  'DEPARTMENT',
  'DOWNLOAD',
  'ELLIPSIS',
  'EMPTY_FILE',
  'EPASSI_LOGO_V2',
  'EPASSI_LOGO',
  'ESTONIA',
  'EVA_REFRESH',
  'EYE',
  'FACE',
  'FILE_SEARCH',
  'FILTER',
  'FINLAND',
  'FRISKVARD',
  'GLOBE_HUMAN',
  'GLOBE',
  'GOOGLE_PLAY',
  'HEART_FILLED',
  'HOME',
  'HOUSE',
  'INFO',
  'INFO_OUTLINE',
  'INTL_EN',
  'INTL_FI',
  'INTL_SV',
  'LIST',
  'LOTUS',
  'MAIL',
  'MAN_WALKING',
  'MENU',
  'NONE',
  'PENCIL',
  'PERSONAL',
  'PERSON_OUTLINE',
  'PEOPLE',
  'PHONE',
  'PIN',
  'PLACEHOLDER_BICYCLE',
  'PLACEHOLDER_COMMUTE',
  'PLACEHOLDER_CULTURE',
  'PLACEHOLDER_ERGOSAFE',
  'PLACEHOLDER_FRISKVARD',
  'PLACEHOLDER_LUNCH',
  'PLACEHOLDER_PRIVATE_HEALTHCARE',
  'PLACEHOLDER_SPORT',
  'PLACEHOLDER_UNKNOWN',
  'PLUS',
  'PNG_FILE',
  'PRICE_TAG',
  'QUESTION_MARK',
  'RUNNING_SHOE',
  'ROCKET',
  'SCALES',
  'SEARCH',
  'SHARE',
  'SHIELD_FILLED',
  'SHIELD',
  'SMARTPHONE',
  'STAR',
  'STATISTICS',
  'SUCCESS',
  'SWEDEN',
  'THEATER',
  'TRAIN',
  'UPLOAD',
  'USER',
  'USERS',
  'WALLET',
  'WAVING_HAND',
  'XLS_FILE',
] as const;
export type IconType = (typeof iconNames)[number];

export type SVGIconType =
  | 'BICYCLE'
  | 'CUTLERY'
  | 'DEPARTMENT'
  | 'EYE'
  | 'FRISKVARD'
  | 'HOUSE'
  | 'LOTUS'
  | 'MAN_WALKING'
  | 'PIN'
  | 'PRIVATE_HEALTHCARE'
  | 'RUNNING_SHOE'
  | 'SCALES'
  | 'THEATER'
  | 'TRAIN'
  | 'USERS'
  | 'WALLET';

const icons: Record<IconType, JSX.Element> = {
  ADDRESS: <Address />,
  ALERT_TRIANGLE: <AlertTriangle />,
  ALERT_CIRCLE: <AlertCircle />,
  ARROWS_RIGHT_LEFT: <ArrowsRigthLeft />,
  APP_STORE: <AppStore />,
  ARROW_DOWN: <ArrowDown />,
  ARROW_OUTLINE: <ArrowOutline />,
  ARROW_UP: <ArrowUp />,
  ARROW: <Arrow />,
  ARROW_LEFT: <ArrowLeft />,
  ARROW_RIGHT: <ArrowRight />,
  ARROW_ROTATION: <ArrowRotation />,
  BANKID: <BankID />,
  BICYCLE: <Bicycle />,
  BUY: <Buy />,
  BUY_NOW: <BuyNow />,
  CALENDAR: <Calendar />,
  CHECKMARK: <Checkmark />,
  CHEVRON_DOUBLE: <ChevronDoubleIcon />,
  CHEVRON_DOWN: <ChevronDown />,
  CHEVRON_RIGHT: <ChevronRight />,
  CLOCK: <Clock />,
  CLOSE_OUTLINE: <CloseOutline />,
  CLOSE_WINDOW: <CloseWindow />,
  CUTLERY: <Cutlery />,
  COPY: <Copy />,
  DEPARTMENT: <Department />,
  DOWNLOAD: <Download />,
  ELLIPSIS: <Ellipsis />,
  EMPTY_FILE: <EmptyFile />,
  EPASSI_LOGO_V2: <EpassiLogoV2 />,
  EPASSI_LOGO: <EpassiLogo />,
  ESTONIA: <Estonia />,
  EVA_REFRESH: <EvaRefresh />,
  EYE: <Eye />,
  FACE: <Face />,
  FILE_SEARCH: <FileSearch />,
  FILTER: <Filter />,
  FINLAND: <Finland />,
  FRISKVARD: <Friskvard />,
  GLOBE_HUMAN: <GlobeHuman />,
  GLOBE: <Globe />,
  GOOGLE_PLAY: <GooglePlay />,
  HEART_FILLED: <HeartFilled />,
  HOME: <Home />,
  HOUSE: <House />,
  INFO: <Info />,
  INFO_OUTLINE: <InfoOutline />,
  INTL_EN: <IntlEn />,
  INTL_FI: <IntlFi />,
  INTL_SV: <IntlSv />,
  LIST: <List />,
  LOTUS: <Lotus />,
  MAIL: <Mail />,
  MAN_WALKING: <ManWalking />,
  MENU: <Menu />,
  NONE: <None />,
  PENCIL: <Pencil />,
  PERSONAL: <Personal />,
  PERSON_OUTLINE: <PersonOutline />,
  PEOPLE: <People />,
  PHONE: <Phone />,
  PIN: <Pin />,
  PLACEHOLDER_BICYCLE: <PlaceholderBicycle />,
  PLACEHOLDER_COMMUTE: <PlaceholderCommute />,
  PLACEHOLDER_CULTURE: <PlaceholderCulture />,
  PLACEHOLDER_ERGOSAFE: <PlaceholderErgosafe />,
  PLACEHOLDER_FRISKVARD: <PlaceholderFriskvard />,
  PLACEHOLDER_LUNCH: <PlaceholderLunch />,
  PLACEHOLDER_PRIVATE_HEALTHCARE: <PlaceholderPrivateHealthcare />,
  PLACEHOLDER_SPORT: <PlaceholderSport />,
  PLACEHOLDER_UNKNOWN: <PlaceholderUnknown />,
  PLUS: <Plus />,
  PNG_FILE: <PngFile />,
  PRICE_TAG: <PriceTag />,
  QUESTION_MARK: <QuestionMark />,
  RUNNING_SHOE: <RunningShoe />,
  ROCKET: <Rocket />,
  SCALES: <Scales />,
  SEARCH: <Search />,
  SHARE: <Share />,
  SHIELD_FILLED: <ShieldFilled />,
  SHIELD: <Shield />,
  SMARTPHONE: <Smartphone />,
  STAR: <Star />,
  STATISTICS: <Statistics />,
  SUCCESS: <Success />,
  SWEDEN: <Sweden />,
  THEATER: <Theater />,
  TRAIN: <Train />,
  UPLOAD: <Upload />,
  USER: <User />,
  USERS: <Users />,
  WALLET: <Wallet />,
  WAVING_HAND: <WavingHand />,
  XLS_FILE: <XlsFile />,
};

const svgIcons: Record<SVGIconType, FunctionComponent> = {
  BICYCLE: Bicycle,
  CUTLERY: Cutlery,
  DEPARTMENT: Department,
  EYE: Eye,
  FRISKVARD: Friskvard,
  HOUSE: House,
  LOTUS: Lotus,
  MAN_WALKING: ManWalking,
  PIN: Pin,
  PRIVATE_HEALTHCARE: PersonOutline,
  RUNNING_SHOE: RunningShoe,
  SCALES: Scales,
  THEATER: Theater,
  TRAIN: Train,
  USERS: Users,
  WALLET: Wallet,
};

export const SVGIcon = (type: SVGIconType) => {
  const Icon = svgIcons[type];
  if (!Icon) {
    throw new Error(`No icon found for type ${type}`);
  }
  return Icon;
};

interface Props {
  type: IconType;
  size?: IconSize;
  className?: string;
  onClick?: () => void;
  light?: boolean;
}

export const Icon = (props: Props) => {
  return (
    <IconInner name={props.type} {...props}>
      {icons[props.type]}
    </IconInner>
  );
};
