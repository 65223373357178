import HumanizeDuration, { DurationOptions } from 'humanize-duration';
import { Duration, toSeconds } from 'iso8601-duration';
import useLocale from 'i18n/contexts/useLocale';

export type FormattedDurationProps = { value: Duration } & Omit<
  DurationOptions,
  'language'
>;

const FormattedDuration = ({ value, ...props }: FormattedDurationProps) => {
  const {
    locale: { language },
  } = useLocale();
  return (
    <span>
      {HumanizeDuration(toSeconds(value) * 1000, {
        language,
        ...props,
      })}
    </span>
  );
};

export default FormattedDuration;
