import { Country } from 'namespace/common';

export enum IntlLanguage {
  en = 'en',
  fi = 'fi',
  sv = 'sv',
  se = 'se',
}

export class IntlLocaleKey {
  language: IntlLanguage;

  country: Country | undefined;

  constructor(language: IntlLanguage, country?: Country) {
    this.language = language;
    this.country = country;
  }

  get icu() {
    return !!this.country ? this.language + '-' + this.country : this.language;
  }
}

export const IntlLocales = {
  en_International: new IntlLocaleKey(IntlLanguage.en),
  en_GB: new IntlLocaleKey(IntlLanguage.en, Country.GB),
  sv_SE: new IntlLocaleKey(IntlLanguage.sv, Country.SE),
  fi_FI: new IntlLocaleKey(IntlLanguage.fi, Country.FI),
  sv_FI: new IntlLocaleKey(IntlLanguage.sv, Country.FI),
};

export const DefaultLocale = IntlLocales.en_GB;

export default IntlLocales;
