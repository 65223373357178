import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import profileReducer, {
  STATE_NAMESPACE as ProfileNamespace,
} from './profile/profile.slice';
import userInfoReducer, {
  STATE_NAMESPACE as UserInfoNamespace,
} from './userInfo/userInfo.slice';

import benefitsReducer, {
  STATE_NAMESPACE as BenefitsNamespace,
} from './benefits/benefits.slice';

import authorizeReducer, {
  STATE_NAMESPACE as AuthorizeNamespace,
} from './authorization/authorization.slice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const reducers = combineReducers({
  [ProfileNamespace]: profileReducer,
  [UserInfoNamespace]: userInfoReducer,
  [BenefitsNamespace]: benefitsReducer,
  [AuthorizeNamespace]: authorizeReducer,
});

export const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type StoreType = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
