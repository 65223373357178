import { createContext } from 'react';

import { KycInit, KycStatus } from 'namespace/kyc';
import { ContextProp } from './context';

export interface KycContextProps {
  initKyc: ContextProp<KycInit>;
  kycStatus: ContextProp<KycStatus>;
  redirectUrl: ContextProp<string>;
}

export const KycContext = createContext<KycContextProps>({} as KycContextProps);
