import config from 'app-common/config';
import { Env } from 'globals/env';
import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';
import RootComponent from 'RootComponent';
import { getBackendUrl } from 'utils/url.utils';

if (process.env.NODE_ENV === 'development') {
  window.ENV = process.env as unknown as Env;
}

config({
  api: { url: getBackendUrl() },
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
