import { setLocale } from 'yup';
import messages from 'app-common/utils/intl-wrapper/schemas.translations';

export const useYupLocale = () => {
  setLocale({
    mixed: {
      required: () => ({
        translation: messages.required,
      }),
      notType: (ref) => {
        switch (ref.type) {
          case 'number':
            return { translation: messages.mixedNumberTypeError };
          case 'string':
            return { translation: messages.mixedStringTypeError };
          case 'date':
            return { translation: messages.mixedDateTypeError };
          default:
            return { translation: messages.mixedGeneralTypeError };
        }
      },
    },
    number: {
      positive: () => ({ translation: messages.positive }),
      min: ({ min }: { min: any }) => ({
        translation: messages.min,
        value: min,
      }),
      max: ({ max }: { max: any }) => ({
        translation: messages.max,
        value: max,
      }),
    },
    string: {
      email: () => ({ translation: messages.email }),
      min: ({ min }: { min: any }) => ({
        translation: messages.minString,
        value: min,
      }),
      max: ({ max }: { max: any }) => ({
        translation: messages.maxString,
        value: max,
      }),
    },
    array: {
      min: ({ min }: { min: any }) => {
        return {
          translation: messages.min,
          value: min,
        };
      },
      max: ({ max }: { max: any }) => ({
        translation: messages.max,
        value: max,
      }),
    },
  });
};
