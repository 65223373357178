import { MessageDescriptor } from 'react-intl';
import messages from 'app-common/globals/benefits.translations';

export enum BenefitType {
  CULTURE = 'CULTURE',
  ERGOSAFE = 'ERGOSAFE',
  FLEX = 'FLEX',
  HEALTH_2 = 'HEALTH_2',
  LUNCH = 'LUNCH',
  SPORT = 'SPORT',
  TRAVEL = 'TRAVEL',
  PERSONAL = 'PERSONAL',
  FRISKVARD = 'FRISKVARD',
  BICYCLE = 'BICYCLE',
  PRIVATE_HEALTHCARE = 'PRIVATE_HEALTHCARE',
}

export const BenefitTranslation: {
  [key in BenefitType]: MessageDescriptor;
} = {
  [BenefitType.CULTURE]: messages.culture,
  [BenefitType.ERGOSAFE]: messages.ergosafe,
  [BenefitType.FLEX]: messages.flex,
  [BenefitType.HEALTH_2]: messages.health2,
  [BenefitType.LUNCH]: messages.lunch,
  [BenefitType.SPORT]: messages.sport,
  [BenefitType.TRAVEL]: messages.travel,
  [BenefitType.PERSONAL]: messages.personal,
  [BenefitType.FRISKVARD]: messages.friskvard,
  [BenefitType.BICYCLE]: messages.bicycle,
  [BenefitType.PRIVATE_HEALTHCARE]: messages.privateHealthcare,
};
