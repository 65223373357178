import { useContext } from 'react';
import { KycContext, KycContextProps } from 'context/kyc-context';
import { ReportContext, ReportContextProps } from './report-context';
import {
  ReceiptHandlingContext,
  ReceiptHandlingContextProps,
} from './receipt-handling-context';
import { BikeContext, BikeContextProps } from 'context/bike-context';
import { ContextProp } from './context';

export function createContextProp<T>(
  value: T | undefined,
  setValue: (value: T) => void,
): ContextProp<T> {
  return {
    value,
    setValue,
  };
}
export const useContexts = () => {
  const reportContext = useContext<ReportContextProps>(ReportContext);
  const kycContext = useContext<KycContextProps>(KycContext);
  const receiptHandlingContext = useContext<ReceiptHandlingContextProps>(
    ReceiptHandlingContext,
  );
  const bikeContext = useContext<BikeContextProps>(BikeContext);
  return {
    reportContext,
    kycContext,
    receiptHandlingContext,
    bikeContext,
  };
};
