import { createContext } from 'react';
import { EmployerDetails, Years } from 'namespace/reports';
import { Currency } from 'namespace/common';
import { ContextProp } from './context';

export interface ReportContextProps {
  selectedYears: ContextProp<Years>;
  employer?: EmployerDetails;
  selectedCompany: ContextProp<EmployerDetails>;
  currency?: Currency;
}

export const ReportContext = createContext<ReportContextProps>(
  {} as ReportContextProps,
);
