const PREFIX = 'epassi';

export const LANGUAGE_KEY = 'language';
export const AUTH_TOKEN_KEY = 'auth-token';
export const CURRENT_USER_KEY = 'current-user';
export const CURRENT_ENTITY_KEY = 'current-entity';
export const CURRENT_USER_NAME_KEY = 'current-user-name';
export const CURRENT_ENTITY_ID = 'current-entity-id';
export const CURRENT_RELEASE_VERSION = 'current-release-version';
export const PAYMENT_ID_KEY = 'paymentId';
export const ADDITIONAL_PAYMENT_DATA_KEY = 'additionalPaymentData';
export const DOMAIN_KEY = 'domain';
export const KYC_KEY = 'kyc';
export const ADMIN_TOKEN_PREFIX = 'admin_token';
export const TOKEN_PREFIX = 'token';
export const ADMIN_FORNTOX_STORED_INVOICES_DATA =
  'ADMIN_FORNTOX_STORED_INVOICES_DATA';
export const ADYEN_SWISH_PAYMENT_ID_KEY = 'adyenSwishPaymentIdKey';
export const BANK_ID_KEY = 'bankId';

const localStorageService = {
  get: (key: string) => {
    // Quickfix of Android WebView issue
    if (!localStorage) {
      return undefined;
    }
    return localStorage.getItem(`${PREFIX}-${key}`);
  },
  set: (key: string, value: string) => {
    localStorage.setItem(`${PREFIX}-${key}`, value);
  },
  remove: (key: string) => {
    localStorage.removeItem(`${PREFIX}-${key}`);
  },
  parse: (key: string) => {
    const textValue = localStorage.getItem(`${PREFIX}-${key}`);
    return JSON.parse(textValue!);
  },
  stringify: (key: string, value: Object) => {
    localStorage.setItem(`${PREFIX}-${key}`, JSON.stringify(value));
  },
};

export default localStorageService;
