export enum Environments {
  DEVELOPMENT = 'development',
  TEST = 'test',
  PRODUCTION = 'production',
}

export enum RequestStatus {
  pending = 'pending',
  idle = 'idle',
  success = 'success',
  error = 'error',
}

export enum Units {
  SEK = 'SEK',
  EUR = 'EUR',
}

export enum Months {
  JANUARY,
  FEBRUARY,
  MARCH,
  APRIL,
  MAY,
  JUNE,
  JULY,
  AUGUST,
  SEPTEMBER,
  OCTOBER,
  NOVEMBER,
  DECEMBER,
}

export enum LoginMethod {
  PHONE = 'phone',
  BANK_ID = 'bankId',
  EMAIL = 'email',
}

export const GO_BACK = -1;
export const SWEDISH_SITE_COUNT = '17 000';
