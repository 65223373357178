import { useIntl } from 'react-intl';
import { useCallback, useMemo } from 'react';
import { useDomainContext } from 'context/domain/domain-context';
import countryToCurrency from 'country-to-currency';

type CountryKey = keyof typeof countryToCurrency;

type CurrencyCode = Intl.NumberFormatOptions['currency'];

export const useDomainCurrency = (): CurrencyCode => {
  const { country } = useDomainContext();
  return country ? countryToCurrency[country as CountryKey] : 'EUR';
};

const useFormatCurrencySymbol = () => {
  const { formatNumberToParts } = useIntl();
  const domainCurrency = useDomainCurrency();
  return useCallback(
    (currency: CurrencyCode = domainCurrency) =>
      formatNumberToParts(1.0, {
        style: 'currency',
        currency,
      }).find((part) => part.type === 'currency')?.value,
    [domainCurrency, formatNumberToParts],
  );
};

export const useDomainCurrencySymbol = () => {
  const formatCurrencySymbol = useFormatCurrencySymbol();
  return useMemo(formatCurrencySymbol, [formatCurrencySymbol]);
};

export default useFormatCurrencySymbol;
