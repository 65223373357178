import { AxiosError } from 'axios';

import { useApi, BaseResponse } from './api-commons';
import ApiProperties from 'api/ApiProperties';

const { get, post } = useApi();

export const checkTokenValidity = (
  error: (response: BaseResponse<void>) => void,
) => {
  get('/api/check_token_validity')
    .then(() => {})
    .catch((reason) => {
      error(reason.response);
    });
};

export const getUserInfo = async (): Promise<UserDetails> => {
  const res = await get('/api/user/v1');
  return res.data.response;
};

export const authorizeUser = async (
  subjectAction: string,
  params: AuthorizeRequest,
): Promise<CapabilityParams> => {
  const res = await post(`/api/authorize/action/${subjectAction}`, params);
  return res.data;
};

export const getUserFeatures = (
  success: (response: BaseResponse<string[]>) => void,
  error: (response: AxiosError) => void,
) => {
  get(ApiProperties.USER_FEATURES)
    .then(success, error)
    .catch((reason) => {
      error(reason.response);
    });
};

export interface AuthorizeRequest {
  subject_type: string;
  subject_id?: number;
}

export interface CapabilityParams {
  subject_id: number | undefined;
  subject_type: string;
  country: string;
}

export interface UserBalanceDetails {
  available_balance?: Amount;
  type?: string;
  deductible_percentage?: string;
}
export interface BalanceDetailsItems {
  items: UserBalanceDetails[];
}

export const getLoggedUserBalanceDetails = (): Promise<BalanceDetailsItems> =>
  get(ApiProperties.USER_BALANCES_DETAILS).then((res) => res.data.response);
