import { ReactNode, useEffect, useRef } from 'react';
import cn from 'classnames';
import { Container } from 'components/base/container/container';
import { Icon } from 'app-common/components/Icon/Icon';
import { Button } from 'components/base/button/button';
import styles from './validation-message.module.scss';
import typography from 'app-common/styles/typography.module.scss';

export enum ValidationMessageTypeEnum {
  SUCCESS = 'SUCCESS',
  ALERT = 'ALERT',
  ALERT_CIRCLE = 'ALERT_CIRCLE',
}

export type ValidationMessageType = ValidationMessageTypeEnum;

export interface ValidationMessageProps {
  title?: string | ReactNode;
  message?: string | ReactNode;
  className?: string;
  buttonText?: string | ReactNode;
  buttonTestId?: string;
  showCloseButton?: boolean;
  handleClose?: () => void;
  onButtonClick?: () => void;
  type?: ValidationMessageType;
}

export const ValidationMessage = ({
  title,
  message,
  className,
  buttonText,
  buttonTestId,
  onButtonClick,
  showCloseButton = false,
  handleClose,
  type = ValidationMessageTypeEnum.ALERT,
}: ValidationMessageProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current && message) {
      containerRef.current.scrollIntoView();
    }
  }, [message]);

  return (
    <div ref={containerRef}>
      <Container type='row' className={cn(styles.containerStyle, className)}>
        {type === ValidationMessageTypeEnum.ALERT && (
          <div className={cn(styles.iconContainer, styles.alertContainer)}>
            <Icon type='ALERT_TRIANGLE' light={true} />
          </div>
        )}

        {type === ValidationMessageTypeEnum.ALERT_CIRCLE && (
          <div
            className={cn(styles.iconContainer, styles.alertCircleContainer)}
          >
            <Icon type='ALERT_CIRCLE' light={true} />
          </div>
        )}

        {type === 'SUCCESS' && (
          <div className={cn(styles.iconContainer, styles.successContainer)}>
            <Icon type='SUCCESS' className={styles.success} />
          </div>
        )}
        <div className={styles.contentContainer}>
          <Container type='row' className={styles.messageContainer}>
            <div>
              <span className={typography.interBold16}>{title}</span>
              <span className={typography.interRegular16}>{message}</span>
            </div>
            {buttonText && (
              <Button data-test={buttonTestId} onClick={onButtonClick}>
                {buttonText}
              </Button>
            )}
          </Container>
          {showCloseButton && (
            <Icon
              type='CLOSE_WINDOW'
              size='MEDIUM'
              className={styles.closeIcon}
              onClick={handleClose}
            />
          )}
        </div>
      </Container>
    </div>
  );
};
