import { createContext } from 'react';
import { ContextProp } from 'context/context';
import { EmployerDetails } from 'namespace/reports';

export interface BikeContextProps {
  employer?: EmployerDetails;
  selectedCompany: ContextProp<EmployerDetails>;
}

export const BikeContext = createContext<BikeContextProps>(
  {} as BikeContextProps,
);
