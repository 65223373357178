import {
  createContext,
  useCallback,
  useContext,
  useState,
  useMemo,
  FunctionComponent,
  ReactNode,
  useRef,
} from 'react';

import cn from 'clsx';

import styles from './ModalProvider.module.scss';

import useClickOutside from 'app-common/hooks/useClickOutside/useClickOutside';
import { Icon } from 'app-common/components/Icon/Icon';

const TAG = 'ModalProvider';

export type ModalSize = 'sm' | 'md' | 'lg' | 'xl';

export interface ModalContextProps {
  open: (
    modal: JSX.Element,
    onCloseCallback?: () => void,
    size?: ModalSize,
  ) => void;
  close: () => void;
}

const ModalContext = createContext<ModalContextProps | undefined>(void 0);

export const useModal = () => {
  const value = useContext(ModalContext);

  if (!value) {
    throw new Error(
      'Modal context can only be used inside the ModalContext.Provider',
    );
  }

  return value;
};

export const ModalProvider: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [modal, setModal] = useState<JSX.Element | null>();
  const [onCloseCallback, setOnCloseCallback] = useState<() => void | null>(
    () => () => {},
  );
  const [modalSize, setModalSize] = useState<ModalSize>('md');

  const close = useCallback(() => {
    if (onCloseCallback) onCloseCallback();
    setModal(null);
  }, [onCloseCallback]);

  useClickOutside(modalRef, () => {
    if (modal) close();
  });

  const open = useCallback(
    (
      _modal: JSX.Element,
      _onCloseCallback: (() => void) | undefined,
      _size?: ModalSize,
    ) => {
      setModal(_modal);
      setModalSize(_size || 'md');
      if (_onCloseCallback) setOnCloseCallback(() => () => _onCloseCallback());
    },
    [],
  );

  const value = useMemo(
    () => ({
      open,
      close,
    }),
    [close, open],
  );

  return (
    <ModalContext.Provider value={value}>
      {children}
      {modal && (
        <div className={cn(styles.modal, { [styles.open]: !!modal })}>
          <div ref={modalRef}>
            {!!modal && (
              <div
                role='dialog'
                className={cn(styles.dialog, {
                  [styles[modalSize]]: modalSize,
                })}
              >
                <button type='button' className={styles.close} onClick={close}>
                  <Icon type='CLOSE_WINDOW' size='MEDIUM' />
                </button>
                <div>{modal}</div>
              </div>
            )}
          </div>
        </div>
      )}
    </ModalContext.Provider>
  );
};

ModalProvider.displayName = TAG;
