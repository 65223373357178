import { useLocale } from 'i18n';
import NumberParser from 'intl-number-parser';
import { useCallback } from 'react';

const numberParserCache: Record<string, ReturnType<typeof NumberParser>> = {};

const cachedGetNumberParser = (icu: string) => {
  if (!numberParserCache[icu]) {
    numberParserCache[icu] = NumberParser(icu);
  }
  return numberParserCache[icu];
};

type ParseDecimalOptions = {
  icu?: string;
};

const useParseDecimal = () => {
  const {
    locale: { icu: currentLocaleIcu },
  } = useLocale();

  return useCallback(
    (
      number: string | null | undefined,
      { icu = currentLocaleIcu }: ParseDecimalOptions = {},
    ) => {
      if (number == null) return NaN;
      try {
        return cachedGetNumberParser(icu)(number);
      } catch {
        return NaN;
      }
    },
    [currentLocaleIcu],
  );
};

const useParsers = () => {
  const parseDecimal = useParseDecimal();

  return {
    parseDecimal,
  };
};

export default useParsers;
