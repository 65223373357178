import { createContext } from 'react';
import { User } from 'namespace/receipt-handling';
import { ContextProp } from './context';

export interface ReceiptHandlingContextProps {
  user: ContextProp<User>;
  isMail: ContextProp<boolean>;
  benefitSourceIds: ContextProp<number[]>;
  selectedBenefitSourceId: ContextProp<number | undefined>;
}

export const ReceiptHandlingContext =
  createContext<ReceiptHandlingContextProps>({} as ReceiptHandlingContextProps);
