import localStorageService, {
  AUTH_TOKEN_KEY,
  CURRENT_USER_KEY,
  CURRENT_USER_NAME_KEY,
  CURRENT_ENTITY_ID,
  ADMIN_TOKEN_PREFIX,
  KYC_KEY,
} from 'local-storage/local-storage';

export const removeUnusedDataFromLocalStorage = () => {
  localStorageService.remove(AUTH_TOKEN_KEY);
  localStorageService.remove(CURRENT_USER_KEY);
  localStorageService.remove(CURRENT_USER_NAME_KEY);
  localStorageService.remove(CURRENT_ENTITY_ID);
  localStorageService.remove(ADMIN_TOKEN_PREFIX);
  localStorageService.remove(KYC_KEY);
};
