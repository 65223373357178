import {
  useState,
  useMemo,
  FunctionComponent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
} from 'react';
import {
  Notification,
  NotificationMessageType,
  NotificationMessage,
} from 'components/base/notification/notification';
import { ValidationMessageTypeEnum } from 'components/validation-message/validation-message';

/**
 * Used for E2E tests
 */
const TAG = 'NotificationProvider';

export interface NotificationContextProps {
  success: ({ title, description }: NotificationMessage) => void;
  error: ({ title, description }: NotificationMessage) => void;
  partialError: ({ title, description }: NotificationMessage) => void;
  close: () => void;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(
  void 0,
);

export const useNotification = () => {
  const value = useContext(NotificationContext);

  if (!value) {
    throw new Error(
      'Notification context can only be used inside the NotificationContext.Provider',
    );
  }

  return value;
};

export const NotificationProvider: FunctionComponent<{
  children?: ReactNode;
}> = ({ children }) => {
  const [notification, setNotification] =
    useState<NotificationMessageType | null>();

  const close = useCallback(() => {
    setNotification(null);
  }, []);

  const value = useMemo(
    () => ({
      success: (props: NotificationMessage) => {
        setNotification({
          type: ValidationMessageTypeEnum.SUCCESS,
          ...props,
        });
      },
      error: (props: NotificationMessage) => {
        setNotification({
          type: ValidationMessageTypeEnum.ALERT,
          ...props,
        });
      },
      partialError: (props: NotificationMessage) => {
        setNotification({
          type: ValidationMessageTypeEnum.ALERT_CIRCLE,
          ...props,
        });
      },
      close,
    }),
    [close],
  );

  return (
    <NotificationContext.Provider value={value}>
      {children}
      {notification ? (
        <Notification
          title={notification.title}
          description={notification.description}
          closeNotification={close}
          type={notification.type}
          preserve={notification.preserve}
          closeTime={notification.closeTime}
        />
      ) : null}
    </NotificationContext.Provider>
  );
};

NotificationProvider.displayName = TAG;
